import * as React from 'react'
import { useState } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import InputField from '../components/form/InputField'
import StillHaveQuestions from '../components/StillHaveQuestions'
import GiftBoxAnimation from '../components/GiftBoxAnimation'
import FormWrapper from '../components/form/FormWrapper'
import isEmail from '../components/form/validators/isEmail'
import isPhoneNumber from '../components/form/validators/isPhone'

const EmailSignUp = () => {
  const [form, setForm] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    consent: true,
  })
  const updateForm = e => {
    setForm(f => {
      let newForm = { ...f }
      newForm[e.target.name] = e.target.value
      return newForm
    })
  }
  const onChangeHandler = e => {
    setForm(f => ({ ...f, [e.target.name]: e.target.checked }))
  }
  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo title="CONTACT US" noindex={true} />

      <h1 className="text-red-700 text-center text-3xl lg:text-5xl pt-12">E-MAIL SIGN-UP FORM</h1>
      <p className="text-2xl text-center pt-2 my-5 w-10/12 mx-auto">
        Receive a $200 discount when you first subscribe to our weekly emails.
      </p>
      <div className="w-10/12 max-w-[1366px] mx-auto flex flex-col lg:flex-row">
        {/* Email Signup PAGE  
         SVG Animated Icons */}
        <div className="w-full max-w-[500px] lg:w-1/2 mx-auto">
          <GiftBoxAnimation />
        </div>
        <div className="w-full mx-auto lg:w-1/2 flex flex-col justify-center items-start">
          <FormWrapper
            className="w-full space-y-3"
            name={'Email Signup'}
            action={'/thank-you-email-subscribe/'}
            form={form}
            conversionEventName={'email_subscribe'}
          >
            <p>
              By joining our email list, you will be the first to know about exciting new arrivals,
              special markdown prices, first look at our new catalogs, and more!
            </p>
            <InputField
              name={'name'}
              label={'Name'}
              form={form}
              onChange={updateForm}
              required={true}
            />
            <InputField
              name={'phone'}
              label={'Phone'}
              required={true}
              validator={isPhoneNumber}
              form={form}
              onChange={updateForm}
            />
            <InputField
              name={'email'}
              label={'E-Mail'}
              required={true}
              validator={isEmail}
              form={form}
              onChange={updateForm}
            />

            <InputField
              name={'message'}
              type="textarea"
              rows={4}
              label={'Message'}
              form={form}
              required={true}
              onChange={updateForm}
            />
            <label className="text-xs text-gray-500 flex flex-row gap-3 items-start my-2">
              <input
                type="checkbox"
                name="consent"
                checked={form['consent']}
                onChange={onChangeHandler}
              />
              I agree to receive updates and follow-up messages about my inquiries via text and
              email.
            </label>
            <button className="left-0 my-7 mx-auto rounded-md bg-red-600 text-white font-bold py-3 px-5 hover:bg-red-800">
              SUBSCRIBE
            </button>
          </FormWrapper>
        </div>
      </div>

      <StillHaveQuestions />

      <Map />
    </Layout>
  )
}

export default EmailSignUp
